html,
body {
  height: 100%;
  color: #002452;

}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  border: 3px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #002452;
  --mdc-protected-button-label-text-color: #fff;
}

/* Targeting all Material buttons */
span.mdc-button__label {
  letter-spacing: 0; 
}


/* .container {
  padding: 0 24px;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2
}

  .container .logo-box {
      margin: 12px 0;
      flex-shrink: 0
  }

      .container .logo-box a {
          display: block
      } */

.container .logo-box img {
  vertical-align: top;
  margin: 3px 0;
}

/* .container .nav-box nav {
      height: 100%
  } */

ul.navbar-nav.mr-auto {
  margin-left: auto;
  margin-right: auto;
}

.navbar {
  background-color: #fff !important;
}

.navbar .container {
  background-color: #fff;
}

.light-blue {
  background-color: #00bcf2!important;
  color: rgba(0, 0, 0, 0.54)!important;
  font-weight: 600;
}


.container .nav-box nav ul li:hover a,
.container .nav-box nav ul li.current-menu-item a,
.nav-link:hover,
.nav-link:focus {
  border-bottom: 3px solid #ef4350;
  color: #ef4350
}

.container .nav-box nav ul li a {
  padding: 19px 8px 16px;
  border-bottom: 3px solid transparent;
  color: #555;
  font-size: 13px;
  font-weight: 600;
  display: block
}

mat-form-field {
  width: 100%;
}


@media (max-width: 480px) {
  div[fxLayout="row"] {
    flex-wrap: wrap;
  }

  div[fxLayout="row"] mat-form-field {
    width: 100% !important;
    max-width: 100% !important;
  }

  ;

}

.center-title-span {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 4px;
  line-height: 24px;
  color: #00BCF2;
  text-transform: uppercase;
  display: block;
  margin-top: 10px;
}

.center-title-join {
  font-size: 34px;
  font-weight: 800;
  letter-spacing: 0;
  color: #002452;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 30px;
}

mat-card-title {
  color: #002452;
}

.center-title {
  color: #002452;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  background: #002452;
  color: white!important;
  height: 95vh;
  max-width: 300px !important;
  width:300px;
}

.mat-mdc-menu-item span.mdc-list-item__primary-text {
  color: white;
  font-size: 13px !important;
  font-weight: 600;
}
.mat-mdc-menu-item  mat-icon.mat-icon.notranslate.material-icons.mat-ligature-font.mat-icon-no-color {
  color: white;
  font-size: 13px !important;
  font-weight: 600;
}
span.mat-mdc-list-item-unscoped-content.mdc-list-item__primary-text {
  color: white;
  font-size: 13px !important;
  font-weight: 600;
}

.mdc-list-item.mdc-list-item--with-one-line {
  height: 29px !important;
  font-weight: 600;
}

mat-expansion-panel.mat-expansion-panel.ng-tns-c80-3.mat-expanded.ng-star-inserted {
  background: #002552;
}



/* Styles for footer with social icons and close menu */
.menu-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-icons {
  display: flex;
  gap: 8px; /* Adjust gap as necessary */
}

.btn-close-menu {
  margin-right: 16px; /* Aligns 'Close Menu' to the right */
  font-size:13px;
}

span.mat-expansion-indicator.ng-tns-c81-4.ng-trigger.ng-trigger-indicatorRotate.ng-star-inserted:after {
  color: rgba(255, 255, 255, 0.54) !important;
}

/* Styles for the social icons and close menu button */
.menu-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.social-icons a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px; /* Space between icons */
}

/* Adjust the size of the icons as necessary */
.mat-icon {
  width: 24px;
  height: 24px;
  /* You may need to set the font-size if the icons are font icons */
}

.btn-close-menu {
  cursor: pointer;
}
