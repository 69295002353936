// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$login-demo-primary: mat.define-palette(mat.$indigo-palette);
$login-demo-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$login-demo-warn: mat.define-palette(mat.$red-palette);

$login-demo-typography: mat.define-typography-config(
  $font-family: 'Montserrat, sans-serif',
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$login-demo-theme: mat.define-light-theme((
  color: (
    primary: $login-demo-primary,
    accent: $login-demo-accent,
    warn: $login-demo-warn,
  ),
  typography: $login-demo-typography
));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core();
@include mat.all-component-themes($login-demo-theme);

